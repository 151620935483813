<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="基本资料"><basic-information></basic-information></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BasicInformation from '@/views/Account/BasicInformation'
export default {
  components: {
    BasicInformation
  },
  name: 'Person',
  data() {
    return {
      imageUrl: ''
    }
  },
  methods: {}
}
</script>

<style scoped></style>
