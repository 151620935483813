<template>
  <div>
    <el-row :gutter="20">
      <el-form ref="elForm" :model="formData" :rules="rules" size="mini" label-width="99px">
        <el-col :span="12">
          <el-row>
            <el-col :span="24">
              <el-form-item label="当前头像" prop="user">
                <el-upload
                  ref="fileUpload"
                  class="avatar-uploader"
                  action=""
                  :http-request="uploadImage"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <el-avatar v-if="imageUrl" shape="circle" :size="100" fit="fill" :src="imageUrl"></el-avatar>
                  <i v-else class="el-icon-plus avatar-uploader-icon">请上传头像</i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="用户名" prop="user">
                <el-input v-model="formData.user" readonly :disabled="true" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="手机号" prop="phone_number">
                <el-input v-model="formData.phone_number" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="性别" prop="gender">
                <el-radio-group v-model="formData.gender" size="medium">
                  <el-radio v-for="(item, index) in genderOptions" :key="index" :label="item.value" :disabled="item.disabled">{{ item.label }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="formData.email" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="所属大学" prop="university">
                <el-input v-model="formData.university" clearable :style="{ width: '100%' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm" :loading="loading">保存</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'BasicInformation',
  data() {
    return {
      imageUrl: '',
      loading: false,
      formData: {
        user: undefined,
        phone_number: undefined,
        gender: '男',
        email: undefined,
        university: undefined
      },
      rules: {
        user: [],
        phone_number: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            message: '邮箱不能为空',
            trigger: 'blur'
          }
        ],
        university: [
          {
            required: true,
            message: '所属大学不能为空',
            trigger: 'blur'
          }
        ]
      },
      genderOptions: [
        {
          label: '男',
          value: '男'
        },
        {
          label: '女',
          value: '女'
        }
      ]
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    uploadImage(data) {
      const formdata = new FormData()
      formdata.append('file', data.file)
      this.$api.user.upload_head_img(formdata).then(res => {
        if (res.data.code === 200) {
          this.imageUrl = res.data.data
          this.$message.success({
            message: '上传头像成功',
            center: true,
            duration: 2000
          })
          this.$router.go(0)
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    getUserInfo() {
      this.$api.user.getLoginUserInfo().then(res => {
        const resData = res.data.data
        this.formData.user = resData.user
        this.formData.phone_number = resData.phone_number
        this.formData.gender = resData.gender
        this.formData.email = resData.email
        this.formData.university = resData.university
        this.imageUrl = resData.head_img
      })
    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          this.loading = true
          this.$api.user.updateUser(this.formData).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: '保存成功',
                center: true,
                duration: 2000
              })
              this.loading = false
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: red;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
